import React from 'react';
import { Box } from 'rebass';

const Articles = ({ children }) => (
  <Box
    pt={2}
  >
    {children}
  </Box>
);

export default Articles;
