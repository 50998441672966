import React from 'react';
import { Box } from 'rebass';

const ArticleSummary = ({ children }) => (
  <Box
    py={3}
  >
    {children}
  </Box>
);

export default ArticleSummary;
